export const ContenedorPagina = ({
  clasesCssAdicionales = 'min-h-screen w-full flex flex-wrap items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-AppBg bg-cover',
  estilosAdicionales = '',
  tituloPagina,
  children,
  mensaje,
}) => {
  return (
    <div className={clasesCssAdicionales}>
      <div
        className={`flex justify-center items-start flex-wrap w-full ${estilosAdicionales}`}
      >
        <div className="w-full flex flex-wrap justify-center items-center">
          <span className="text-center text-21px font-bold text-cendiatra my-25px uppercase">
            {tituloPagina}
          </span>
        </div>
        <div className="bg-white w-5/6 p-4 rounded rounded-t-3xl rounded-b-3xl border border-opacity-50 border-cendiatra shadow-2xl">
          <div className="bg-white px-8 pt-2 rounded-t-3xl rounded-b-3xl">
            {children}
          </div>
        </div>
        <div
          className={`w-10/12 ${
            mensaje ? 'flex' : 'hidden'
          } justify-center items-center my-5`}
        ></div>
      </div>
    </div>
  )
}
