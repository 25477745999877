import React, {useEffect, useState} from 'react'
import {ESTADOS_TRANSACCION, RUTAS_APLICACION} from '../../constantes'
import {useHistory} from 'react-router-dom'
import {aplicarFormatoMoneda} from '../../utils'
import Lottie from 'lottie-react'
import check from '../../imagenes/lotties/check.json'
import clsx from 'clsx'

const EstadoTransaccion = ({estado, mensajes}) => (
  <div className="w-full flex-col justify-center items-center text-center text-cendiatra text-3xl my-10 font-bold flex-wrap">
    <p className="w-full mb-10">{estado.toLocaleUpperCase()}</p>
    {mensajes.map((mensaje, index) => (
      <p key={index} className="w-full mb-10">
        {mensaje}
      </p>
    ))}
    {estado === ESTADOS_TRANSACCION.APROBADA ? (
      <>
        <p className="w-full mt-10 text-cendiatra-rojo-1 text-2xl font-bold text-center">
          Recibirá su factura electrónica en el correo electrónico.
        </p>
        <p className="w-full text-cendiatra text-center text-2xl font-normal mt-10">
          Puede descargar su recibo de caja en la sección "Ver movimientos y
          detalles"
        </p>
      </>
    ) : null}
  </div>
)

const EstadosPagoOtrosServicios = ({transaccionEstadoSaldo}) => {
  const {estadoTransaccion, valor, urlReciboCaja} = transaccionEstadoSaldo

  const [pdfUrl, setPdfUrl] = useState('')

  const history = useHistory()

  const irPagarOtrosServicios = () => {
    history.push(RUTAS_APLICACION.PAGAR_OTROS_SERVICIOS)
  }

  const irVerDetalleYMovimiento = () => {
    history.push(RUTAS_APLICACION.VER_DETALLE_Y_MOVIMIENTO)
  }

  const irAOrdenes = () => {
    history.push(RUTAS_APLICACION.CREAR_ORDEN)
  }

  const finalizar = () => {
    history.push(RUTAS_APLICACION.MAIN)
  }

  const imprimirReciboDeCaja = () => {
    window.open(urlReciboCaja)
  }

  const estadosMensajes = {
    [ESTADOS_TRANSACCION.RECHAZADA]: [
      'Tu pago no ha sido recibido',
      '¿Qué deseas hacer?',
    ],
    [ESTADOS_TRANSACCION.FALLIDA]: [
      'Tu pago no ha sido recibido',
      '¿Qué deseas hacer?',
    ],
    [ESTADOS_TRANSACCION.APROBADA]: [
      <Lottie className="w-52 mx-auto" animationData={check} loop={true} />,
      'Pago exitoso por:',
      `${aplicarFormatoMoneda(valor ?? '')}`,
    ],
    [ESTADOS_TRANSACCION.PENDIENTE]: [
      'Tu cuenta está pendiente por recargar',
      'Generalmente, en unas pocas horas será reflejado el pago en tu cuenta,',
      'nos encontramos en el proceso de validación con tu entidad bancaria para garantizar una transacción exitosa.',
      'Si requieres más información acerca de la transacción, por favor contacta a tu entidad bancaria.',
    ],
    [ESTADOS_TRANSACCION.EXPIRADA]: [
      'Tu cuenta no ha sido recibido',
      '¿Qué deseas hacer?',
    ],
    [ESTADOS_TRANSACCION.NO_AUTORIZADA]: [
      'Tu pago NO ha sido recibido',
      'comunícate con la entidad bancaria',
      '¿Qué deseas hacer?',
    ],
  }

  return (
    <div>
      {estadoTransaccion in estadosMensajes && (
        <EstadoTransaccion
          estado={estadoTransaccion}
          mensajes={estadosMensajes[estadoTransaccion]}
        />
      )}

      {estadoTransaccion !== ESTADOS_TRANSACCION.APROBADA ? (
        <div className="w-full flex justify-evenly items-center m-5">
          {estadoTransaccion !== ESTADOS_TRANSACCION.PENDIENTE ? (
            <button
              key={'Regresar'}
              className="w-40 h-14 rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white text-lg p-1 leading-20px"
              type="button"
              onClick={irPagarOtrosServicios}
            >
              {'Regresar'}
            </button>
          ) : null}

          {estadoTransaccion === ESTADOS_TRANSACCION.PENDIENTE ? (
            <button
              key={'Ver movimientos y detalles '}
              className="w-72 h-14 rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white text-lg p-1 leading-20px"
              type="button"
              onClick={irVerDetalleYMovimiento}
            >
              {'Ver movimientos y detalles '}
            </button>
          ) : (
            <button
              key={'Finalizar'}
              className="w-40 h-14 rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white text-lg p-1 leading-20px"
              type="button"
              onClick={finalizar}
            >
              {'Finalizar'}
            </button>
          )}
        </div>
      ) : (
        <>
          <div className="w-full flex justify-evenly items-center m-5">
            <button
              key={'Finalizar'}
              className="w-40 h-14 rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white text-lg p-1 leading-20px"
              type="button"
              onClick={finalizar}
            >
              {'Volver a Inicio'}
            </button>

            <button
              key={'Crear órdenes'}
              className="w-40 h-14 rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white text-lg p-1 leading-20px"
              type="button"
              onClick={irAOrdenes}
            >
              {'Crear órdenes'}
            </button>

            <button
              key={'Imprimir recibo de caja'}
              className={
                'w-52 h-14 rounded-lg bg-cover bg-no-repeat bg-center text-white text-lg p-1 leading-20px bg-fondoBoton'
              }
              type="button"
              onClick={imprimirReciboDeCaja}
            >
              {'Imprimir recibo de caja'}
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default EstadosPagoOtrosServicios
