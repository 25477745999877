import React from 'react'
import clsx from 'clsx'

const EncabezadoPestanaExternos = ({
  estadoActual,
  actualizarDatos,
  numeroPaginacion,
  titulo,
}) => {
  const irAlTab = (numeroPaginaNuevo) => {
    actualizarDatos(numeroPaginaNuevo)
  }

  return (
    <div
      className={clsx(
        'w-3/12 flex justify-center border-r border-l border-t rounded-t-lg',
        {
          'border-opacity-50 border-cendiatra bg-white':
            estadoActual === numeroPaginacion,
          'border-none bg-none': estadoActual !== numeroPaginacion,
        }
      )}
      onClick={() => irAlTab(numeroPaginacion)}
    >
      <div className="w-10/12 flex justify-center items-center my-5 flex-wrap">
        <p className={`text-lg text-cendiatra text-center`}>{titulo}</p>
        <div className="w-full h-2  bg-cendiatra"></div>
      </div>
    </div>
  )
}

export default EncabezadoPestanaExternos
