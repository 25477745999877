import React, {useContext, useEffect, useState} from 'react'
import {InputMoneda, InputConResultado} from '../inputs'
import {BotonSimple} from '../Botones'
import {ContextoRecargarSaldo} from '../../contexto'
import {useMsal} from '@azure/msal-react'
import {ObtenerEmpresaPorId} from '../../microservicios'
import {useForm, Controller} from 'react-hook-form'
import {ObtenerTodasLasCiudades} from '../../cache/servicios-cache'
import {ContenedorTituloResaltado} from '../Contenedores'
import {aplicarFormatoMoneda} from '../../utils'
import {AlertaDinamica} from '../MostrarEnPantalla'
import {ListaDesplegableConSelector} from '../inputs'

const OtrosServiciosRecargarCuentaServicios = ({
  manejarGuardar,
  datosTransaccion,
  datosServicio,
  datosTiquetera,
  datosSede,
  funcionRegresar,
}) => {
  const contextoRecargarSaldo = useContext(ContextoRecargarSaldo)

  const {accounts} = useMsal()

  const valoresTablaPorDefecto = {
    montoRecarga: '',
    retencionRenta: '',
    rentaTotal: '',
    retencionICA: '',
    ICATotal: '',
    sobretasaBomberil: '',
    bomberilTotal: '',
    retencionIVA: '',
    totalIVA: '',
    valorRecibido: '',
  }
  const [valoresTabla, setValoresTabla] = useState(valoresTablaPorDefecto)
  const [alertaICA, setAlertaICA] = useState(false)
  const [totalTabla, setTotalTabla] = useState(0)
  const [municipio, setMunicipio] = useState({})

  const [todosLosMunicipios, setTodosLosMunicipios] = useState([])

  const idEmpresa = accounts[0]?.idTokenClaims?.city ?? ''

  const {
    trigger,
    control,
    setValue,
    clearErrors,
    setError,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    trigger()
    obtenerDatosEmpresa()
  }, [])

  useEffect(() => {
    const obtenerTodosLosMunicipios = async () => {
      try {
        const todasLasCiudades = await ObtenerTodasLasCiudades()
        setTodosLosMunicipios(todasLasCiudades)
      } catch (error) {
        console.log(error)
      }
    }
    obtenerTodosLosMunicipios()
  }, [])

  const obtenerDatosEmpresa = async () => {
    ObtenerEmpresaPorId(idEmpresa).then((res) => {
      contextoRecargarSaldo.setFormularioRecargarSaldo({
        ...contextoRecargarSaldo.formularioRecargarSaldo,
        identificacionCliente: res?.data?.nit,
      })
      contextoRecargarSaldo.setDataPdf({
        documento: res?.data?.nit,
        nombre: res?.data?.nombre,
        direccion: res?.data?.direccion,
        telefono: res?.data?.telefono,
      })
    })
  }

  const calcularRetencionRenta = (valor) => {
    if (valor && parseInt(datosTransaccion?.totalServicios)) {
      const total = (parseInt(datosTransaccion?.totalServicios) * valor) / 100
      setValoresTabla((prev) => {
        return {
          ...prev,
          rentaTotal: isNaN(total) ? '' : total,
        }
      })
    } else if (!valor) {
      setValoresTabla((prev) => {
        return {
          ...prev,
          rentaTotal: '',
        }
      })
    }
  }

  const calcularRetencionICA = (valor) => {
    if (valor && parseInt(datosTransaccion?.totalServicios)) {
      const total = (parseInt(datosTransaccion?.totalServicios) * valor) / 1000
      setValoresTabla((prev) => {
        return {
          ...prev,
          ICATotal: isNaN(total) ? '' : total,
        }
      })
      setAlertaICA(false)
    } else if (!valor) {
      setValoresTabla((prev) => {
        return {
          ...prev,
          ICATotal: '',
          bomberilTotal: '',
        }
      })
    }
  }

  const calcularSobretasaBomberil = (valor) => {
    if (!valoresTabla?.ICATotal && valor) {
      return setAlertaICA(true)
    }
    if (valor && parseInt(datosTransaccion?.totalServicios)) {
      const total = (valoresTabla?.ICATotal * valor) / 100
      setValoresTabla((prev) => {
        return {
          ...prev,
          bomberilTotal: isNaN(total) || !valoresTabla?.ICATotal ? '' : total,
        }
      })
      setAlertaICA(false)
    } else if (!valor) {
      setValoresTabla((prev) => {
        return {
          ...prev,
          bomberilTotal: '',
        }
      })
      setAlertaICA(false)
    }
  }

  const calcularRetencionIVA = (valor) => {
    if (valor && parseInt(datosTransaccion?.totalIva)) {
      const total = (parseInt(datosTransaccion?.totalIva) * valor) / 100
      setValoresTabla((prev) => {
        return {
          ...prev,
          totalIVA: isNaN(total) ? '' : total,
        }
      })
    } else if (!valor) {
      setValoresTabla((prev) => {
        return {
          ...prev,
          totalIVA: '',
        }
      })
    }
  }
  const calcularValoresEnTabla = (nombrePropiedad, valor) => {
    const funcionesPorPropiedad = {
      retencionRenta: () => calcularRetencionRenta(valor),
      retencionICA: () => calcularRetencionICA(valor),
      sobretasaBomberil: () => calcularSobretasaBomberil(valor),
      retencionIVA: () => calcularRetencionIVA(valor),
    }
    if (nombrePropiedad in funcionesPorPropiedad) {
      funcionesPorPropiedad[nombrePropiedad]()
    }
  }
  const modificarValoresTabla = (nombrePropiedad, valor) => {
    setValoresTabla((prev) => {
      return {
        ...prev,
        [nombrePropiedad]: valor ?? '',
      }
    })
    calcularValoresEnTabla(nombrePropiedad, valor)
    if (nombrePropiedad === 'montoRecarga' && !valor) {
      setValoresTabla((prev) => {
        return {
          ...prev,
          rentaTotal: '',
          ICATotal: '',
          bomberilTotal: '',
        }
      })
    }
  }
  useEffect(() => {
    const calcularValorTotal = () => {
      const valoresNumericos = [
        parseInt(datosTransaccion?.totalTransaccion),
        valoresTabla?.rentaTotal,
        valoresTabla?.ICATotal,
        valoresTabla?.bomberilTotal,
        valoresTabla?.totalIVA,
      ]
        .filter((valor) => valor !== '')
        .map((valor) => parseFloat(valor))
      if (valoresNumericos.length >= 1) {
        const resultado = valoresNumericos
          .slice(1)
          .reduce(
            (acumulador, valor) => acumulador - valor,
            valoresNumericos[0]
          )

        setValoresTabla((prev) => {
          return {
            ...prev,
            valorRecibido:
              parseFloat(resultado) +
                parseFloat(datosTransaccion.totalTransaccion) ?? '',
          }
        })

        return setTotalTabla(resultado)
      } else {
        return setTotalTabla(0)
      }
    }
    calcularValorTotal()
  }, [
    parseInt(datosTransaccion?.totalServicios),
    valoresTabla?.rentaTotal,
    valoresTabla?.ICATotal,
    valoresTabla?.bomberilTotal,
    valoresTabla?.totalIVA,
  ])

  useEffect(() => {
    calcularRetencionRenta(valoresTabla?.retencionRenta)
    calcularRetencionICA(valoresTabla?.retencionICA)
    calcularSobretasaBomberil(valoresTabla?.sobretasaBomberil)
  }, [parseInt(datosTransaccion?.totalServicios), valoresTabla?.ICATotal])

  const manejarMunicipioSeleccionado = (municipio) => {
    if (municipio) {
      setValue('municipio', municipio?.Nombre)
      clearErrors('municipio')
      return setMunicipio(municipio)
    }
    setError('municipio', {type: 'require', message: ''})
    setValue('municipio', '')
    setMunicipio('')
  }

  return (
    <>
      <div className="w-full flex justify-center items-center flex-wrap ">
        <div className="w-11/12 flex justify-end items-center my-2 p-5">
          <div className="w-full flex justify-start items-center flex-wrap">
            <div className="w-full flex justify-start">
              <span className="w-1/12 text-cendiatra items-center justify-center text-lg font-bold">
                Servicios{' '}
              </span>
              <span className="w-2/12 text-cendiatra items-center justify-center text-lg font-bold ml-3 text-end">
                {aplicarFormatoMoneda(datosTransaccion?.totalServicios)}
              </span>
            </div>
            <div className="w-full flex justify-start">
              <span className="w-1/12 text-cendiatra items-center justify-center text-lg font-bold">
                IVA 19%
              </span>
              <span className="w-2/12 text-cendiatra items-center justify-center text-lg font-bold ml-3 text-end">
                {aplicarFormatoMoneda(datosTransaccion?.totalIva)}
              </span>
            </div>
          </div>
        </div>
        <div className="w-11/12 flex justify-end items-center my-2">
          <div className="w-full flex justify-end items-center border-2 border-cendiatra-gris-2 rounded-3xl p-5">
            <span className="w-7/12  text-cendiatra-gris-1 mr-3 text-lg font-bold">
              *Comience a escribir el municipio desde donde está haciendo la
              recarga y al obtener resultados selecciónelo de la lista
              desplegable.
            </span>
            <div className="w-5/12 flex flex-wrap justify-end items-center">
              <span className="w-5/12 text-cendiatra-verde-3 font-bold text-lg text-right pr-5">
                Municipio*
              </span>
              <Controller
                name="municipio"
                control={control}
                rules={{
                  required: true,
                }}
                render={({fieldState: {error}}) => (
                  <ListaDesplegableConSelector
                    titulo={''}
                    estilosPersonalizados={'w-7/12'}
                    estilosInput={`appearance-none rounded relative block w-full text-center p-1.5 border ${
                      error ? 'border-cendiatra-rojo-1' : 'border-cendiatra'
                    }  text-center  rounded-lg focus:outline-none focus:ring-indigo-500  focus:z-10 sm:text-sm`}
                    textoPorDefecto={'ELEGIR'}
                    placeholder={'Ciudad'}
                    datosParaMostrar={todosLosMunicipios}
                    manejarOpcionSeleccionada={manejarMunicipioSeleccionado}
                    textoToolTip={'Seleccione de la lista desplegable'}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <span className="w-8/12 mt-3 text-center text-cendiatra-rojo-1 text-xl font-semibold">
          Es responsabilidad del cliente conocer e ingresar sus tarifas de
          retención.
        </span>
        <span className="w-8/12  text-center text-cendiatra-rojo-1 text-xl font-semibold">
          Recuerde que las retenciones se deben realizar con cada pago. (Art.
          392 ET).
        </span>
        <span className="w-8/12 mb-3 text-center text-cendiatra-rojo-1 text-xl font-semibold">
          Por favor, asesórese para diligenciar correctamente esta información.
        </span>

        <div className="w-10/12 flex justify-center items-center flex-wrap p-2 text-cendiatra font-bold">
          <ContenedorTituloResaltado
            titulo={'TARIFAS DE RETENCIÓN'}
            margenContenedor={'mb-20 border-none'}
            paddingContenedor={
              'w-full flex justify-center items-center flex-wrap '
            }
          >
            <span className="w-8/12 my-3 text-center text-cendiatra-gris-1 text-xl ">
              No olvide que la base para practicar la retención en la fuente,
              inicia a partir de 4 UVT ($ 188.000 COP año 2024).
            </span>
            <InputConResultado
              titulo={
                'Tarifa de retención de Renta (% sobre valor de los servicios)'
              }
              estilosInput={`appearance-none text-center rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-verde-3 font-bold text-14px  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10`}
              placeholder={'0,00%'}
              valor={valoresTabla.retencionRenta}
              funcion={(e) =>
                modificarValoresTabla('retencionRenta', e.floatValue)
              }
              totalCalculado={valoresTabla.rentaTotal}
            />
            <div className="w-full flex justify-center items-center flex-wrap border-2 rounded-lg py-5 my-2">
              <InputConResultado
                titulo={
                  'Tarifa de retención ICA (valor de los servicios * tarifa / 1000)'
                }
                estilosInput={`appearance-none text-center rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-verde-3 font-bold text-14px rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10`}
                placeholder={'0,00'}
                valor={valoresTabla.retencionICA}
                funcion={(e) =>
                  modificarValoresTabla('retencionICA', e.floatValue)
                }
                totalCalculado={valoresTabla.ICATotal}
                descripcion={'Res. DDI-023769 Grandes contribuyentes Bogotá.'}
                porcentaje={false}
                prefix={''}
              />
              <InputConResultado
                titulo={'Sobretasa Bomberil (% sobre el valor del ICA)'}
                estilosInput={`appearance-none text-center rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-verde-3 font-bold text-14px rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10`}
                placeholder={'0,00%'}
                valor={valoresTabla.sobretasaBomberil}
                funcion={(e) =>
                  modificarValoresTabla('sobretasaBomberil', e.floatValue)
                }
                totalCalculado={valoresTabla.bomberilTotal}
              />
              <div className="w-full flex justify-start items-center">
                <div className="w-6/12"></div>
                <div className="w-5/12">
                  <AlertaDinamica
                    mostrarAlerta={alertaICA}
                    mensaje={'Se requiere la tarifa ICA para calcular Bomberil'}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex justify-start items-center ml-2 my-2 border-b-2 pb-5">
              <InputConResultado
                titulo={'Tarifa retención de IVA (% sobre el valor del IVA)'}
                estilosTitulo={'-mt-5'}
                estilosInput={`appearance-none text-center rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-verde-3 font-bold text-14px rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10`}
                valor={valoresTabla.tarifaIVA || ''}
                funcion={(e) =>
                  modificarValoresTabla('retencionIVA', e.floatValue)
                }
                totalCalculado={valoresTabla.totalIVA}
                descripcion={
                  'Aplica solo para los servicios que la ley indica.'
                }
                porcentaje={true}
                estilosDescripcion={'text-cendiatra-verde-3 -mt-5'}
                placeholder={'0,00%'}
              />
            </div>
            <div className="w-full flex justify-end items-center my-2">
              <div className="w-3/12 flex justify-center ">
                <span className=" text-cendiatra-verde-2 font-bold text-start text-2xl">
                  Total a pagar
                </span>
              </div>
              <div className="w-3/12 flex justify-center">
                <span className="text-cendiatra-verde-2 font-bold text-center text-2xl">
                  {aplicarFormatoMoneda(totalTabla)}
                </span>
              </div>
            </div>
            <div className="w-full flex justify-center items-center my-2">
              <span className=" text-cendiatra-gris-1 font-bold text-start text-lg">
                Cendiatra no asume la responsabilidad de errores en la
                información proporcionada por el cliente.
              </span>
            </div>
          </ContenedorTituloResaltado>
        </div>
      </div>
      <div className="w-full flex justify-center items-center mt-1">
        <div className=" flex justify-center items-center pointer-events-auto">
          <button
            className={`opacity-70 group relative w-28 h-10 flex justify-center py-2 px-4 border border-transparent text-lg rounded-md text-cendiatra-verde-2 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra font-bold m-2`}
            type="button"
            onClick={funcionRegresar}
          >
            {' '}
            <u>A</u>trás
          </button>

          <BotonSimple
            texto={'Pagar'}
            estilosBoton={`h-40px w-28 p-1.5 rounded-lg ${
              Object.keys(errors).length === 0 && !alertaICA
                ? 'bg-cendiatra'
                : 'bg-cendiatra-gris-2 pointer-events-none'
            } bg-cendiatra bg-cover bg-no-repeat bg-center text-white font-bold text-14px`}
            funcion={() =>
              manejarGuardar(
                datosTransaccion,
                datosServicio,
                datosTiquetera,
                datosSede,
                valoresTabla,
                municipio,
                totalTabla
              )
            }
            deshabilitado={Object.keys(errors).length !== 0 || alertaICA}
          />
        </div>
      </div>
    </>
  )
}

export default OtrosServiciosRecargarCuentaServicios
