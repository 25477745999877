import React, {useEffect, useState, useCallback, useContext} from 'react'
import TablaDinamica from '../TablaDinamica'
import CeldaConBoton from './CeldaConBoton'
import {BotonSimple} from '../Botones'
import {
  ObtenerTiposDocumentoPorId,
  AgregarServiciosACache,
  ObtenerServicioPorId,
  AgregarPaquetesACache,
  ObtenerDepartamentoPorId,
  ObtenerCiudadPorId,
} from '../../cache/servicios-cache'
import {NavLink, useLocation} from 'react-router-dom'
import {useMsal} from '@azure/msal-react'
import moment from 'moment/moment'
import {ModalConfirmacionEstandar} from '../Modales'
import {ContextoAplicacion} from '../../contexto'
import {ObtenerDetalleAtencion} from '../../microservicios'
import {TEXTO_VISUAL, ROLES_APP, RUTAS_APLICACION} from '../../constantes'
import ImagenUsuario from '../../imagenes/usuarioImagen.svg'
import IconoImprimirActivado from '../../imagenes/iconoImprimirVerde.svg'
import IconoImprimirDesactivado from '../../imagenes/iconoImprimirGris.svg'
import IconoProhibido from '../../imagenes/iconoBloqueoCuadradoRojo.svg'
import {servicioAtencionPendientePorAtender} from '../../constantes'
import {ObtenerSedePorId, ObtenerAliadoPorId} from '../../cache/servicios-cache'

const ResultadosPaciente = () => {
  const [datos, setDatos] = useState([])
  const [foto, setFoto] = useState('')
  const [examenes, setExamenes] = useState([])

  const {accounts} = useMsal()

  const rolUsuario = accounts[0].idTokenClaims?.country ?? ''

  const {state} = useLocation()

  const contextoAplicacion = useContext(ContextoAplicacion)

  const calcularEdad = (fechaNacimiento) => {
    var diferencia = moment(fechaNacimiento).diff(moment(), 'milliseconds')
    var duracion = moment.duration(diferencia)
    return (
      duracion._data.years +
      ' AÑOS ' +
      duracion._data.months +
      ' MESES ' +
      duracion._data.days +
      ' DÍAS'
    ).replaceAll('-', '')
  }

  const obtenerInformacionOrden = useCallback(async () => {
    await AgregarServiciosACache()
    await AgregarPaquetesACache()
    obtenerDetalleAtencion(state?.state?.atencionId)
  }, [])

  const validarExistenciaAlias = async (servicio) => {
    if (servicio.alias) {
      return servicio.alias?.toUpperCase() ?? ''
    } else {
      return (
        (
          await ObtenerServicioPorId(servicio.servicioId)
        )?.Nombre?.toUpperCase() ?? ''
      )
    }
  }

  const obtenerInformacionServicios = async (servicios) => {
    return await Promise.all(
      servicios.map(async (serv) => ({
        id: serv.servicioId ?? '',
        servicio: await validarExistenciaAlias(serv),
        fechaYHoraCreacion:
          moment(serv.fechaAtencion).format('DD-MM-YYYY hh:mm') ?? '',
        fechaYHoraCierre:
          moment(serv.fechaCierre).format('DD-MM-YYYY hh:mm') ?? '',
        medico: serv?.nombreMedico?.toUpperCase() ?? '',
        urlCertificado: serv.urlCertificado ?? '',
        urlDocumentoExamen: serv.urlDocumentoExamen ?? '',
      })) ?? []
    )
  }

  const obtenerInformacionAliado = async (aliadoId) => {
    const informacionAliado = await ObtenerAliadoPorId(aliadoId)
    const ciudadAliado = await ObtenerCiudadPorId(informacionAliado?.CiudadId)

    return `${ciudadAliado?.Nombre} ${informacionAliado?.Nombre}`
  }

  const obtenerDatosUsuario = async (data, tipoDeDocumento) => {
    return {
      'Tipo de documento': tipoDeDocumento.Nombre,
      'No. de documento': data?.datosUsuario?.documento ?? '',
      'Nombres ': `${(data?.datosUsuario?.nombre ?? '').toUpperCase()} ${(
        data?.datosUsuario?.apellidos ?? ''
      ).toUpperCase()}`,
      Sexo: data?.datosUsuario?.sexoId === 1 ? 'FEMENINO' : 'MASCULINO' ?? '',
      Edad: data?.datosUsuario?.fechaNacimiento
        ? calcularEdad(data?.datosUsuario?.fechaNacimiento)
        : '',
      Celular: data?.datosUsuario?.direccion?.celular ?? '',
      'País ': 'COLOMBIA',
      Departamento: data?.datosUsuario?.direccion?.departamentoId
        ? (
            await ObtenerDepartamentoPorId(
              data?.datosUsuario?.direccion?.departamentoId
            )
          )?.Nombre
        : '',
      'Dirección ': (
        data?.datosUsuario?.direccion?.direccion ?? ''
      ).toUpperCase(),
      'Correo Electronico': (
        data?.datosUsuario?.direccion?.correo ?? ''
      ).toUpperCase(),
      'Fecha de atención':
        moment(data?.datosUsuario?.fechaAtencion).format('YYYY-MM-DD') ?? '',
      'Sede o aliado': data?.sedeId
        ? ((await ObtenerSedePorId(data?.sedeId))?.Nombre ?? '')?.toUpperCase()
        : await obtenerInformacionAliado(data?.aliadoId),
    }
  }

  const obtenerDetalleAtencion = async (id) => {
    try {
      const respuesta = await ObtenerDetalleAtencion(id)

      if (respuesta.status === 200) {
        const tipoDeDocumento = await ObtenerTiposDocumentoPorId(
          respuesta.data.datosUsuario.tipoDocumentoId
        )
        const datosUsuario = await obtenerDatosUsuario(
          respuesta.data,
          tipoDeDocumento
        )

        const examenesAtencion = respuesta?.data?.servicios?.map((servicio) => {
          return {
            nombreServicio: servicio?.alias
              ? servicio?.alias
              : servicio?.nombreServicio,
            medico: servicio?.nombreMedico
              ? servicio?.nombreMedico
              : servicioAtencionPendientePorAtender(
                  servicio.estadoServicioId
                ) && !servicio?.nombreMedico
              ? ''
              : TEXTO_VISUAL.TIPO_MEDICO.MEDICO_ALIADO,
            esLaboratorio: servicio?.esLaboratorio,
            urlCertificado: servicio?.urlCertificado,
            urlDocumentoExamen: servicio?.urlDocumentoExamen,
          }
        })
        setDatos(datosUsuario)
        setFoto(respuesta.data?.datosUsuario?.urlFoto ?? '')
        setExamenes(examenesAtencion ?? [])
      } else {
        setExamenes([])
      }
    } catch (error) {
      console.error(error)
      setExamenes([])
    }
  }

  useEffect(() => {
    obtenerInformacionOrden().catch(console.error)
  }, [obtenerInformacionOrden])

  const manejarCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const clickModuloImprimir = (urlCertificado) => {
    if (urlCertificado) {
      var win = window.open(urlCertificado, '_blank')
      win.focus()
    } else {
      contextoAplicacion.setModal({
        abierto: true,
        contenido: (
          <ModalConfirmacionEstandar
            texto={'EL CERTIFICADO NO HA SIDO GENERADO'}
          />
        ),
        botones: [
          {
            nombre: 'Volver',
            click: manejarCerrarModal,
          },
        ],
      })
    }
  }
  const validacionImagenAMostrar = (datosFila, tipoColumna) => {
    if (
      (datosFila.esLaboratorio &&
        tipoColumna === TEXTO_VISUAL.COLUMNA_DETALLE_ATENCION.CERTIFICADO) ||
      (!datosFila.esLaboratorio &&
        tipoColumna === TEXTO_VISUAL.COLUMNA_DETALLE_ATENCION.EXAMEN)
    ) {
      return IconoProhibido
    }

    const tieneUrl =
      (datosFila.esLaboratorio && datosFila.urlDocumentoExamen) ||
      (!datosFila.esLaboratorio && datosFila.urlCertificado)

    return tieneUrl ? IconoImprimirActivado : IconoImprimirDesactivado
  }

  const columnsComercial = React.useMemo(() => [
    {
      Header: 'Exámenes',
      accessor: 'nombreServicio',
      className: 'w-3/12',
    },
    {
      Header: 'Médico',
      accessor: 'medico',
      className: 'w-3/12',
    },
    {
      Header: 'Imprimir Certificado',
      id: 'CeldaConBoton',
      className: 'w-3/12',
      Cell: (row) => (
        <CeldaConBoton
          funcion={() =>
            clickModuloImprimir(row?.row?.original?.urlCertificado)
          }
          imagen={validacionImagenAMostrar(row?.row?.original, 'certificado')}
          deshabilitado={Boolean(
            row?.row?.original?.urlCertificado &&
              !row?.row?.original?.esLaboratorio
          )}
        />
      ),
    },
    {
      Header: 'Imprimir Examen',
      id: 'descargarHistoria',
      className: 'w-3/12',
      Cell: (row) => (
        <CeldaConBoton
          funcion={() =>
            clickModuloImprimir(row?.row?.original?.urlDocumentoExamen)
          }
          imagen={validacionImagenAMostrar(row?.row?.original, 'examen')}
          deshabilitado={Boolean(
            row?.row?.original?.urlDocumentoExamen &&
              row?.row?.original?.esLaboratorio
          )}
        />
      ),
    },
  ])

  const columnsMedico = React.useMemo(() => [
    {
      Header: 'Exámenes',
      accessor: 'nombreServicio',
      className: 'w-4/12',
    },
    {
      Header: 'Médico',
      accessor: 'medico',
      className: 'w-4/12',
    },
    {
      Header: 'Imprimir Certificado',
      id: 'descargarCertificado',
      className: 'w-2/12',
      Cell: (row) => (
        <CeldaConBoton
          funcion={() =>
            clickModuloImprimir(row?.row?.original?.urlCertificado)
          }
          imagen={
            row.row.original.urlCertificado
              ? IconoImprimirActivado
              : IconoImprimirDesactivado
          }
          deshabilitado={Boolean(row?.row?.original?.urlCertificado)}
        />
      ),
    },
    {
      Header: 'Imprimir Examen',
      id: 'descargarHistoria',
      className: 'w-2/12',
      Cell: (row) => (
        <CeldaConBoton
          funcion={() =>
            clickModuloImprimir(row.row.original?.urlDocumentoExamen)
          }
          imagen={
            (row.row.original?.urlDocumentoExamen &&
              row.row.orginal?.esLaboratorio) ||
            rolUsuario === ROLES_APP.MEDICO
              ? IconoImprimirActivado
              : IconoImprimirDesactivado
          }
          deshabilitado={Boolean(
            (row.row.original?.urlDocumentoExamen &&
              row.row.orginal?.esLaboratorio) ||
              rolUsuario === ROLES_APP.MEDICO
          )}
        />
      ),
    },
  ])

  const retornarColumns = (rol) => {
    if (rol === ROLES_APP.MEDICO) {
      return columnsMedico
    } else {
      return columnsComercial
    }
  }

  return (
    <div className="w-full flex justify-center items-center flex-wrap ">
      <div className="w-full flex justify-center items-center mt-20">
        <span className="text-cendiatra text-4xl font-semibold">
          {TEXTO_VISUAL.DETALLE_DE_LA_ATENCION.TITULO}
        </span>
      </div>
      <div className="w-full flex justify-center items-center my-20">
        <img
          className="h-56 "
          src={foto ? foto : ImagenUsuario}
          alt="imagen usuario"
          style={{borderRadius: '50%'}}
        />
      </div>
      <div className="w-10/12 flex flex-wrap ">
        {Object.keys(datos).map((dato, index) => (
          <div
            className="w-4/12 flex flex-wrap justify-center items-center py-4"
            key={index}
          >
            <div className="w-7/12 flex flex-wrap justify-center items-center">
              <span className="w-full text-lg text-start text-cendiatra font-semibold">
                {dato}
              </span>
              <span className="w-full text-xl text-gray-400 text-start">
                {datos[`${dato}`]}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="w-11/12 flex flex-wrap justify-center items-center my-20">
        <div className="w-full">
          <TablaDinamica
            columns={retornarColumns(rolUsuario)}
            data={examenes}
          />
        </div>
      </div>
      <div className="w-full flex justify-center items-center mb-20">
        <NavLink to={RUTAS_APLICACION.GESTION_ATENCIONES}>
          <BotonSimple
            texto={'Volver a atenciones'}
            estilosBoton={
              'w-48 h-14 rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white text-lg'
            }
            tipoDeBoton={'button'}
          />
        </NavLink>
      </div>
    </div>
  )
}

export default ResultadosPaciente
