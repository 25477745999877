import React, {useEffect, useState} from 'react'
import TablaDinamica from '../TablaDinamica'
import {
  EncabezadoFondo,
  CeldaTextoEstilosEditables,
  CeldaFormatoDinero,
} from '../Tablas'
import {
  FachadaObtenerPagosOtrosServicios,
  ObtenerMovimientosUltimoMesPorEmpresaId,
} from '../../microservicios'
import {useMsal} from '@azure/msal-react'
import {useHistory} from 'react-router-dom'
import {BotonConImagen, ExportarCSVMovimientos} from '../Botones'
import moment from 'moment'
import IconoDescargar from '../../imagenes/botonDescargar.svg'
import {FORMATO_FECHAS} from '../../constantes/formatoFechas'

const TablaPagosOtrosServicios = () => {
  const headers = [
    {label: 'Fecha', key: 'fecha'},
    {label: 'Valor', key: 'valorTotal'},
    {label: 'Detalle', key: 'detalle'},
    {label: '', key: 'urlDocumento'},
    {label: 'Id del Pago', key: 'atencionId'},
  ]

  const history = useHistory()

  const {accounts} = useMsal()
  const idEmpresa = accounts[0]?.idTokenClaims?.city ?? ''

  const [datos, setDatos] = useState([])
  const [datosCsv, setDatosCsv] = useState([])

  useEffect(() => {
    const ObtenerDatosCuenta = async () => {
      try {
        const response = await FachadaObtenerPagosOtrosServicios(idEmpresa)
        if (response.status === 204) {
          return
        }
        const datosPromesas = response.data.map(async (elemento) => {
          return {
            fecha: moment(elemento.fecha).format(
              FORMATO_FECHAS.DD_MM_YYY_SLASH
            ),
            valor: elemento.valorTotal,
            detalle: `Pago otros servicios ${
              elemento.conTiquetera ? 'Con Tiquetera' : ''
            } - Aprobado,`,
            urlDocumento: elemento.urlDocumento,
          }
        })

        const datos = await Promise.all(datosPromesas)

        setDatos(datos)
      } catch (error) {
        console.error(error)
      }
    }

    ObtenerDatosCuenta()
    manejarObtenerDatosExportar()
  }, [])

  const verResultadoPaciente = (id) => {
    history.push('/resultadospaciente', {state: {atencionId: id}})
  }

  const manejarObtenerDatosExportar = async () => {
    try {
      const response = await ObtenerMovimientosUltimoMesPorEmpresaId(idEmpresa)
      if (response.status === 204) {
        return
      }
      const datosPromesas = response.data.map(async (elemento) => ({
        fecha: moment(elemento.fecha).format(FORMATO_FECHAS.DD_MM_YYY_SLASH),
        valor: elemento.valor,
        tipoMovimiento: elemento.tipoMovimiento,
        detalle: elemento.detalle,
        atencionId: elemento.atencionId,
      }))
      setDatosCsv(await Promise.all(datosPromesas))
    } catch (error) {
      console.error(error)
    }
  }

  const imprimirReciboDeCaja = (urlRecibo) => {
    if (urlRecibo) {
      var win = window.open(urlRecibo, '_blank')
      win.focus()
    }
  }

  const columns = React.useMemo(() => [
    {
      Header: () => (
        <EncabezadoFondo
          titulo={'FECHA'}
          estilosEncabezado={'bg-white text-cendiatra font-bold text-17px'}
        />
      ),
      accessor: 'fecha',
      className: 'w-3/12',
    },
    {
      Header: () => (
        <EncabezadoFondo
          titulo={'VALOR'}
          estilosEncabezado={'bg-white text-cendiatra font-bold text-17px'}
        />
      ),
      accessor: 'valor',
      className: 'w-3/12',
      Cell: (row) => (
        <CeldaFormatoDinero
          estilos={
            'flex justify-center items-center text-center pointer-events-none'
          }
          valor={row.row.original.valor}
        />
      ),
    },
    {
      Header: () => (
        <EncabezadoFondo
          titulo={'DETALLE'}
          estilosEncabezado={'bg-white text-cendiatra font-bold text-17px'}
        />
      ),
      accessor: 'detalle',
      className: 'w-4/12',
      Cell: (row) => (
        <CeldaTextoEstilosEditables
          texto={row?.row?.original?.detalle}
          estilosTexto={'normal-case'}
          estilos={'w-full flex justify-center items-center'}
          imagen={IconoDescargar}
        />
      ),
    },
    {
      accessor: ' urlDocumento',
      className: 'w-10 ml-2',
      Cell: (row) => (
        <>
          {row?.row?.original?.urlDocumento ? (
            <BotonConImagen
              funcionAEjecutar={() => {
                imprimirReciboDeCaja(row.row.original.urlDocumento)
              }}
              estilos={'w-full flex justify-center items-center'}
              imagen={IconoDescargar}
            />
          ) : (
            ''
          )}
        </>
      ),
    },
  ])

  return (
    <>
      <div className="w-full flex justify-center items-center">
        <TablaDinamica
          columns={columns}
          data={datos}
          fondoTabla={'bg-white'}
          paginacion={true}
          bordeInferior={''}
        />
      </div>
      <div className="w-full flex justify-end items-center">
        <div className="w-4/12 fles justify-center items-center -mt-28">
          <ExportarCSVMovimientos
            encabezados={headers}
            obtenerDatos={datosCsv}
            nombreArchivo={'movimientosYdetalles.csv'}
            texto={'Exportar Datos Del Último Mes'}
          />
        </div>
      </div>
    </>
  )
}

export default TablaPagosOtrosServicios
