import React, {useState, useRef, useEffect} from 'react'
import clsx from 'clsx'

const ListaDesplegableConSelector = ({
  titulo = '',
  estilosPersonalizados,
  estilosInput,
  textoPorDefecto,
  datosParaMostrar,
  placeholder,
  manejarOpcionSeleccionada,
  valor = '',
  textoToolTip = '',
  colorTextoPersonalizado = 'text-cendiatra-verde-2',
}) => {
  const [inputValue, setInputValue] = useState('')
  const [selected, setSelected] = useState('')
  const [abierto, setAbierto] = useState(false)

  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef.current && abierto) {
      inputRef.current.focus()
    }
  }, [abierto])

  useEffect(() => {
    if (valor) {
      const empresa = datosParaMostrar.find((elemento) => elemento.Id === valor)
      setSelected(empresa?.Nombre)
    }
  }, [valor])

  useEffect(() => {
    const clickAfuera = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setAbierto(false)
      }
    }
    document.addEventListener('mousedown', clickAfuera)
    return () => {
      document.removeEventListener('mousedown', clickAfuera)
    }
  }, [inputRef])

  return (
    <div
      className={` flex ${estilosPersonalizados} justify-center items-center flex-wrap text-13px`}
      ref={inputRef}
    >
      <label className="block text-13px font-medium text-cendiatra  w-full mt-1.5">
        {titulo}
      </label>
      <div className="w-full font-medium relative  z-50 uppercase">
        <span
          className={clsx(
            'absolute w-full text-center z-50 py-3 px-3 rounded-lg -top-12 text-white ml-1 bg-cendiatra-verde-2 shadow-lg shadow-slate-600',
            {
              'visible opacity-100': !selected && textoToolTip,
              'invisible opacity-0': selected || !textoToolTip,
            }
          )}
        >
          {textoToolTip}
        </span>

        <button
          onClick={() => setAbierto(!abierto)}
          type="button"
          data-dropdown-toggle="dropdown"
          className={clsx(
            'w-full p-1.5 flex items-center justify-between border rounded-lg',
            estilosInput,
            {
              'text-cendiatra-gris-2': !selected,
              [colorTextoPersonalizado]: selected,
            }
          )}
        >
          {selected
            ? selected?.length > 25
              ? selected?.substring(0, 25) + '...'
              : selected
            : textoPorDefecto}
          <svg
            class="w-2.5 h-2.5 ms-3 text-cendiatra-gris-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <ul
          className={clsx('bg-white mt-2 overflow-y-auto absolute w-full', {
            'max-h-60 border border-cendiatra-verde-2 rounded-lg': abierto,
            'max-h-0': !abierto,
          })}
        >
          <div className="flex w-full items-center px-2 sticky top-0 bg-white ">
            <input
              type="text"
              ref={inputRef}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value.toLowerCase())}
              placeholder={placeholder}
              className=" placeholder:text-cendiatra-gris-2 p-2 outline-none w-full  border-b-2 border-cendiatra-verde-2"
            />
          </div>
          <li
            key="-1"
            value=""
            className={`p-2 text-sm text-cendiatra-gris-3 hover:bg-cendiatra-verde-2 hover:text-white mt-1 block`}
            onClick={() => {
              setSelected('')
              setAbierto(false)
              setInputValue('')
              manejarOpcionSeleccionada('')
            }}
          >
            {' '}
            {textoPorDefecto}{' '}
          </li>
          {datosParaMostrar?.map((elemento) => (
            <li
              key={elemento?.Id}
              className={clsx(
                'p-2 text-sm hover:bg-cendiatra-verde-2 hover:text-white mt-1',
                {
                  'bg-cendiatra-verde-2 text-white':
                    elemento?.Nombre?.toLowerCase() === selected?.toLowerCase(),
                  block: elemento?.Nombre?.toLowerCase().includes(inputValue),
                  hidden: !elemento?.Nombre?.toLowerCase().includes(inputValue),
                }
              )}
              onClick={() => {
                if (
                  elemento?.Nombre?.toLowerCase() !== selected.toLowerCase()
                ) {
                  setSelected(elemento?.Nombre)
                  setAbierto(false)
                  setInputValue('')
                }
                manejarOpcionSeleccionada(elemento)
              }}
            >
              {elemento?.Nombre}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ListaDesplegableConSelector
