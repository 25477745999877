import {getRestApi} from './ApiAxios'
import {dbComun, dbCliente} from '../cache/bases-datos'
import {
  ObtenerCiudadPorId,
  ObtenerServicioPorId,
  ObtenerPaquetePorId,
} from '../cache/servicios-cache'
import {ObtenerUsuarioPorId} from './Usuario'
import {TEXTO_VISUAL} from '../constantes'
import moment from 'moment'

export const ObtenerOrdenPorId = async (idOrden) => {
  const restApi = await getRestApi()
  let respuesta = await restApi.get(
    'orden-function/ObtenerOrdenPorId?Id=' + idOrden
  )
  let ciudad = await ObtenerCiudadPorId(respuesta.data.ciudadId)
  let usuario = await ObtenerUsuarioPorId(respuesta.data.usuarioId)
  return {
    fechaCreacion: respuesta.data.fechaCreacion,
    fechaSolicitudAtencion: moment(respuesta.data.fechaAtencion).format(
      'YYYY-MM-DD'
    ),
    fechaVencimientoOrden: respuesta.data.fechaVencimiento,
    horaSolicitadaAtencion: moment(respuesta.data.fechaAtencion).format(
      'HH:mm'
    ),
    ciudadId: respuesta.data.ciudadId,
    ciudad: ciudad.Nombre,
    tipoDeDocumento: usuario.data.tipoDocumento,
    numeroDocumento: usuario.data.numeroDocumento,
    nombre: usuario.data.nombres,
    apellido: usuario.data.apellidos,
    cargo: usuario.data.cargo,
    celular: usuario.data.celular,
    correo: usuario.data.correo,
    tipoExamen: respuesta.data.tipoExamenId,
    empresaHijaId: respuesta.data.empresaHijaId,
    observacionesMedicas: respuesta.data.observacionesMedicas,
    servicios: await Promise.all(
      respuesta.data.servicios.map(async function (x) {
        return await ObtenerServicioPorId(x.examenId)
      })
    ),
    paquetes: await Promise.all(
      respuesta.data.paquetes.map(async function (x) {
        return await ObtenerPaquetePorId(x.examenId)
      })
    ),
    descontarSaldoPortal: respuesta.data.descontarSaldoPortal,
  }
}

export const ActualizarOrden = async (orden) => {
  const restApi = await getRestApi()
  return await restApi.patch('orden-function/ActualizarOrden', orden)
}

export const ObtenerOrdenesPorEmpresa = async (empresaId, rol) => {
  const restApi = await getRestApi()
  let respuesta = await restApi.get(
    'orden-function/ObtenerOrdenesPorIdEmpresa?empresaId=' + empresaId
  )
  let listaOrdenes = respuesta.data
  let tiposExamen = await dbComun.table('TiposExamen').toArray()

  let estadosOrden = await dbComun.table('EstadosOrden').toArray()

  let sedes = await dbComun.table('Sedes').toArray()

  let conceptosOrden = await dbComun.table('ConceptosOrden').toArray()

  let empresas = await dbCliente.table('Empresas').toArray()

  let ordenes = listaOrdenes.map((orden) => ({
    id: orden.id,
    identificador: orden.identificador,
    numeroOrdenyFechaCreacion: {
      Fila1: orden.id,
      Fila2: orden.fechaCreacion,
    },
    nombresyDocumento: {
      Fila1: orden.usuario?.nombres + ' ' + orden.usuario?.apellidos,
      Fila2: orden.usuario?.numeroDocumento,
    },
    tipoExamen: tiposExamen.find((x) => x.Id == orden.tipoExamenId)?.Nombre,
    estado: estadosOrden.find((x) => x.Id == orden.estadoOrdenId)?.Nombre,
    sedeyFechaAtencion: {
      Fila1: sedes.find((x) => x.Id == orden.sedeId)?.Nombre ?? '',
      Fila2:
        orden.estadoOrdenId == 4 || orden.estadoOrdenId == 3
          ? orden.fechaAtencion
          : '',
    },
    concepto: conceptosOrden.find((x) => x.Id == orden.conceptoId)?.Nombre,
    centroCostosEmpresaUsuaria:
      empresas.find((x) => x.Id == orden.empresaId)?.Nombre?.toUpperCase() ??
      '',
  }))

  if (rol == 'medico') {
    ordenes = ordenes.filter(
      (x) => x.estado == TEXTO_VISUAL.ORDENES.ESTADO.ATENDIDA
    )
  }
  return Promise.resolve(ordenes)
}

export const ActualizarEstadoOrden = async (orden) => {
  const restApi = await getRestApi()
  return await restApi.patch('orden-function/ActualizarEstadoOrden', orden)
}

export const ObtenerOrdenesPorSemanaActualYEmpresaId = async (
  empresaId,
  fecha
) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'gato-api-function/ObtenerResumenSemanaPorEmpresaId',
    {
      params: {
        empresaId,
        fecha,
      },
    }
  )
}

export const DescargaPlantillaOrdenesPorEmpresaId = async (empresaId) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'gato-api-function/DescargaPlantillaOrdenesPorEmpresaId',
    {
      params: {
        empresaId,
      },
    }
  )
}

export const CargarArchivoOrdenesPorEmpresaId = async (payload) => {
  const apiReq = await getRestApi()
  return await apiReq.put(
    'gato-api-function/CargarArchivoOrdenesPorEmpresaId',
    payload
  )
}
