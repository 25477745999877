import {useMsal} from '@azure/msal-react'
import React, {useEffect, useContext} from 'react'
import {
  imagenAtenciones,
  imagenAtencionesGris,
  imagenRecargarCuentaHabilitado,
  imagenRecargarCuentaDeshabilitado,
  imagenVerMovimientosHabilitado,
  imagenVerMovimientosDeshabilitado,
  imagenOrdenes,
  imagenOrdenesGris,
  CrearYGestionarOrdenesHabilitado,
  CrearYGestionarOrdenesDeshabilitado,
  DescargaMasivaHabilitado,
  DescargaMasivaDeshabilitado,
} from '../../imagenes/imagenesMenu/Index'
import {
  imagenHome,
  BotonAgregar,
  BotonAgregarGris,
  imagenCerrar,
  imagenHomeGris,
  imagenCerrarGris,
  CendiatraLogo,
} from '../../imagenes'
import ImagenEstandar from '../ImagenEstandar'
import ImagenFondo from '../ImagenFondo'
import {ObtenerEmpresaPorId} from '../../microservicios/Empresa'
import {ContextoAplicacion} from '../../contexto'
import {TEXTO_VISUAL, RUTAS_APLICACION} from '../../constantes'
import {validarRolAsignado} from '../../utils'

import MenuDinamico from './MenuDinamico'

import {useFeatureIsOn} from '@growthbook/growthbook-react'
import {TEXTO_FEATURE_FLAGS} from '../../constantes/featureFlags.js'

const BarraDeNavegacion = ({cerrarSesion}) => {
  const {accounts} = useMsal()

  const rolUsuario = accounts[0]?.idTokenClaims?.country ?? ''

  const idEmpresa = accounts[0]?.idTokenClaims?.city ?? ''

  const contexto = useContext(ContextoAplicacion)

  const habilitarDescargaMasiva = useFeatureIsOn(
    TEXTO_FEATURE_FLAGS.DESCARGA_MASIVA
  )

  const featureFlagDescargaMasiva = habilitarDescargaMasiva
    ? {
        imagen: DescargaMasivaHabilitado,
        imagenDeshabilitado: DescargaMasivaDeshabilitado,
        texto: TEXTO_VISUAL.HOME.NAVEGACION.DESCARGA_MASIVA,
        ruta: RUTAS_APLICACION.DESCARGA_MASIVA,
        rol: '',
        tipoFacturacion: [
          TEXTO_VISUAL.HOME.TIPOS_FACTURACION.CONTADO,
          TEXTO_VISUAL.HOME.ROL.ADMINISTRATIVO,
          TEXTO_VISUAL.HOME.TIPOS_FACTURACION.CREDITO,
        ],
      }
    : null

  useEffect(() => {
    ObtenerEmpresaPorId(idEmpresa).then((res) => {
      contexto.setInfoEmpresa({
        nombre: res.data.nombre.toUpperCase(),
        imagen: res.data.logoUrl,
        tipoFacturacion: res.data.facturacionEmpresa.tipoFacturacion,
        correoFacturacion: res.data.facturacionEmpresa.correoFacturacion,
      })
    })
  }, [])

  const arrayMenus = [
    {
      imagen: imagenHome,
      imagenDeshabilitado: imagenHomeGris,
      texto: TEXTO_VISUAL.HOME.NAVEGACION.INICIO,
      ruta: RUTAS_APLICACION.HOME,
      rol: [TEXTO_VISUAL.HOME.ROL.MEDICO, TEXTO_VISUAL.HOME.ROL.ADMINISTRATIVO],
    },
    {
      imagen: imagenOrdenes,
      imagenDeshabilitado: imagenOrdenesGris,
      texto: TEXTO_VISUAL.HOME.NAVEGACION.GESTION_DE_ORDENES,
      ruta: RUTAS_APLICACION.ORDENES,
      rol: '',
      tipoFacturacion: [
        TEXTO_VISUAL.HOME.TIPOS_FACTURACION.CREDITO,
        TEXTO_VISUAL.HOME.ROL.MEDICO,
        TEXTO_VISUAL.HOME.ROL.ADMINISTRATIVO,
      ],
    },
    {
      imagen: imagenOrdenes,
      imagenDeshabilitado: imagenOrdenesGris,
      texto: TEXTO_VISUAL.HOME.NAVEGACION.GESTION_DE_ORDENES,
      ruta: RUTAS_APLICACION.ORDENES,
      rol: '',
      tipoFacturacion: [
        TEXTO_VISUAL.HOME.TIPOS_FACTURACION.CONTADO,
        TEXTO_VISUAL.HOME.ROL.MEDICO,
      ],
    },
    {
      imagen: CrearYGestionarOrdenesHabilitado,
      imagenDeshabilitado: CrearYGestionarOrdenesDeshabilitado,
      texto: TEXTO_VISUAL.HOME.NAVEGACION.CREAR_GESTIONAR_ORDENES,
      ruta: RUTAS_APLICACION.ORDENES,
      rol: '',
      subMenus: [
        {
          titulo: TEXTO_VISUAL.HOME.NAVEGACION.CREAR_ORDEN,
          ruta: RUTAS_APLICACION.CREAR_ORDEN,
        },
        {
          titulo: TEXTO_VISUAL.HOME.NAVEGACION.GESTIONAR_ORDENES,
          ruta: RUTAS_APLICACION.ORDENES,
        },
      ],
      tipoFacturacion: [
        TEXTO_VISUAL.HOME.TIPOS_FACTURACION.CONTADO,
        TEXTO_VISUAL.HOME.ROL.ADMINISTRATIVO,
      ],
    },
    {
      imagen: imagenAtenciones,
      imagenDeshabilitado: imagenAtencionesGris,
      texto: TEXTO_VISUAL.HOME.NAVEGACION.GESTION_ATENCIONES,
      ruta: RUTAS_APLICACION.GESTION_ATENCIONES,
      rol: [TEXTO_VISUAL.HOME.ROL.MEDICO, TEXTO_VISUAL.HOME.ROL.ADMINISTRATIVO],
    },
    {
      imagen: imagenRecargarCuentaHabilitado,
      imagenDeshabilitado: imagenRecargarCuentaDeshabilitado,
      texto: TEXTO_VISUAL.HOME.NAVEGACION.REALIZAR_PAGOS,
      ruta: '/',
      rol: '',
      tipoFacturacion: [
        TEXTO_VISUAL.HOME.TIPOS_FACTURACION.CONTADO,
        TEXTO_VISUAL.HOME.ROL.ADMINISTRATIVO,
      ],
      subMenus: [
        {
          titulo: TEXTO_VISUAL.HOME.NAVEGACION.RECARGAR_CUENTA,
          ruta: RUTAS_APLICACION.RECARGAR_SALDO,
        },
        {
          titulo: TEXTO_VISUAL.HOME.NAVEGACION.PAGAR_OTROS_SERVICIOS,
          ruta: RUTAS_APLICACION.PAGAR_OTROS_SERVICIOS,
        },
      ],
    },
    {
      imagen: imagenRecargarCuentaHabilitado,
      imagenDeshabilitado: imagenRecargarCuentaDeshabilitado,
      texto: TEXTO_VISUAL.HOME.NAVEGACION.REALIZAR_PAGOS,
      ruta: '/',
      rol: '',
      tipoFacturacion: [
        // TEXTO_VISUAL.HOME.TIPOS_FACTURACION.CREDITO, DESHABILITAD MIENTRAS SE DESARROLLAN LAS FEATURES
        TEXTO_VISUAL.HOME.ROL.ADMINISTRATIVO,
      ],
      subMenus: [
        {
          titulo: TEXTO_VISUAL.HOME.NAVEGACION.FACTURACION,
          ruta: '',
        },
        // {
        //   titulo: TEXTO_VISUAL.HOME.NAVEGACION.PAGAR_OTROS_SERVICIOS,
        //   ruta: RUTAS_APLICACION.PAGAR_OTROS_SERVICIOS,
        // }, Deshabilitado porque no corresponde a la salida a produccion
      ],
    },
    {
      imagen: BotonAgregar,
      imagenDeshabilitado: BotonAgregarGris,
      texto: TEXTO_VISUAL.HOME.NAVEGACION.CREAR_ORDEN,
      ruta: RUTAS_APLICACION.CREAR_ORDEN,
      rol: '',
      deshailitado: false,
      tipoFacturacion: [
        TEXTO_VISUAL.HOME.TIPOS_FACTURACION.CREDITO,
        TEXTO_VISUAL.HOME.ROL.ADMINISTRATIVO,
      ],
    },
    {
      imagen: imagenVerMovimientosHabilitado,
      imagenDeshabilitado: imagenVerMovimientosDeshabilitado,
      texto: TEXTO_VISUAL.HOME.NAVEGACION.VER_MOVIMIENTOS,
      ruta: RUTAS_APLICACION.VER_DETALLE_Y_MOVIMIENTO,
      rol: '',
      tipoFacturacion: [
        TEXTO_VISUAL.HOME.TIPOS_FACTURACION.CONTADO,
        TEXTO_VISUAL.HOME.ROL.ADMINISTRATIVO,
      ],
    },
    {
      ...featureFlagDescargaMasiva,
    },
    {
      imagen: imagenCerrar,
      imagenDeshabilitado: imagenCerrarGris,
      texto: TEXTO_VISUAL.HOME.NAVEGACION.CERRAR_SESION,
      ruta: '',
      rol: [TEXTO_VISUAL.HOME.ROL.MEDICO, TEXTO_VISUAL.HOME.ROL.ADMINISTRATIVO],
      funcion: cerrarSesion,
    },
  ]

  const ObtenerDatosMenu = (rol) => {
    const menu = arrayMenus.filter((elemento) => {
      const tieneRol =
        elemento?.rol?.includes(rol) && validarRolAsignado(elemento.rol, rol)

      const tieneTipoFacturacion =
        elemento.tipoFacturacion &&
        elemento.tipoFacturacion.includes(rol) &&
        elemento.tipoFacturacion.includes(
          contexto.infoEmpresa.tipoFacturacion.toUpperCase()
        )

      return tieneRol || tieneTipoFacturacion
    })

    return menu ?? []
  }

  return (
    <div className=" w-full flex justify-between items-center relative bg-white py-2">
      <div className="w-3/12 flex justify-center items-center">
        <ImagenEstandar
          imagen={CendiatraLogo}
          texto={'logoCendiatra'}
          estilosPersonalizados={'w-52 ml-5'}
        />
      </div>
      <div className="flex justify-center items-center w-6/12">
        <MenuDinamico datos={ObtenerDatosMenu(rolUsuario)} />
      </div>
      <div className="w-3/12 flex justify-center items-center">
        <ImagenFondo url={contexto.infoEmpresa?.imagen} estilos={'w-52 mr-5'} />
      </div>
    </div>
  )
}

export default BarraDeNavegacion
