import {getRestApi} from './ApiAxios'

export const GenerarCertificadoCustodiaClinicaPorEmpresaId = async (
  empresaId
) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'gato-api-function/GenerarCertificadoCustodiaClinicaPorEmpresaId',
    {
      params: {empresaId},
    }
  )
}

export const FachadaObtenerPagosOtrosServicios = async (empresaId) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'gato-api-function/FachadaObtenerPagosOtrosServicios',
    {
      params: {empresaId},
    }
  )
}
