import {getRestApi} from './ApiAxios'
import {dbComun, dbCliente} from '../cache/bases-datos'
import moment from 'moment'

export const CrearNuevaOrdenFachada = async (orden) => {
  const restApi = await getRestApi()
  return await restApi.put('fachada-function/FachadaCrearOrden', orden)
}

export const FachadaActualizarOrden = async (orden) => {
  const restApi = await getRestApi()
  return await restApi.patch('fachada-function/FachadaActualizarOrden', orden)
}

export const FachadaObtenerOrdenesGestionOrdenes = async (payload) => {
  const restApi = await getRestApi()
  try {
    let respuesta = await restApi.post(
      'fachada-function/ObtenerOrdenesGestionOrdenes',
      payload
    )
    return organizarInformacion(respuesta)
  } catch (error) {
    console.log(error)
    return []
  }
}

export const FachadaObtenerAtencionesGestionAtenciones = async (payload) => {
  const restApi = await getRestApi()
  try {
    let respuesta = await restApi.post(
      'fachada-function/ObtenerAtencionesGestionAtenciones',
      payload
    )
    return organizarInformacion(respuesta)
  } catch (error) {
    console.log(error)
    return []
  }
}

export const organizarInformacion = async (respuesta) => {
  let listaOrdenes = respuesta.data

  let tiposExamen = await dbComun.table('TiposExamen').toArray()

  let estadosOrden = await dbComun.table('EstadosOrden').toArray()

  let sedes = await dbComun.table('Sedes').toArray()

  let aliados = await dbComun.table('Aliados').toArray()

  let conceptosDeAptitud = await dbComun.table('ConceptoDeAptitud').toArray()

  let empresas = await dbCliente.table('Empresas').toArray()

  let ciudades = await dbComun.table('Ciudades').toArray()

  let estadosAtencion = [
    {
      Id: 1,
      Nombre: 'Activa',
    },
    {
      Id: 2,
      Nombre: 'En Proceso',
    },
    {
      Id: 3,
      Nombre: 'Atendida',
    },
    {
      Id: 4,
      Nombre: ' En proceso modificación',
    },
    {
      Id: 5,
      Nombre: 'En proceso cancelación',
    },

    {
      Id: 6,
      Nombre: 'Cancelada',
    },
  ]

  const validarSedeOAliado = (sedeId, aliadoId) => {
    if (sedeId !== 0) {
      return sedes.find((x) => x.Id == sedeId)?.Nombre ?? ''
    }
    let aliado = aliados?.find((x) => x?.Id === aliadoId)
    let ciudad = ciudades?.find((x) => x?.Id === aliado?.CiudadId)
    return `${ciudad?.Nombre} ${aliado?.Nombre}`
  }
  const validarExistenciaDeConceptos = (arrayConceptos, conceptoString) => {
    if (arrayConceptos?.length > 1) {
      return 'ATENCIÓN CON VARIOS CONCEPTOS VER DETALLE'
    }
    const conceptoId = arrayConceptos?.[0] ?? ''
    const conceptoEncontrado = conceptosDeAptitud?.find(
      (x) => x?.Id === conceptoId
    )
    return conceptoEncontrado?.Nombre ?? conceptoString?.toUpperCase()
  }

  const validarConceptoServicios = (orden) => {
    const conceptos =
      orden?.servicios
        ?.map((servicio) => servicio.descripcionConcepto)
        .filter((concepto) => !!concepto) ?? []

    if (!conceptos.length) return ''

    if (conceptos.length > 1) return 'ATENCIÓN CON VARIOS CONCEPTOS VER DETALLE'

    return (
      orden?.servicios[0]?.descripcionConcepto ??
      validarExistenciaDeConceptos(orden?.conceptosIds, orden?.conceptoAptitud)
    )
  }

  if (respuesta?.data) {
    let ordenes = {
      informacionPaginacion: respuesta?.data?.paginacion,
      listaDeOrdenes: listaOrdenes?.datos.map((orden) => ({
        id: orden.id,
        atencionId: orden.id,
        idOrdenPortalCliente: orden.ordenId,
        identificador: orden.identificador,
        conceptoDescripcion: orden?.informacionConceptos
          ? orden?.informacionConceptos[0]?.conceptoDescripcion?.toUpperCase()
          : '',
        fechaCreacion: orden.fechaCreacion,
        numeroOrdenyFechaCreacion: {
          Fila1: orden.id,
          Fila2: orden.fechaCreacion,
        },
        nombresyDocumento: {
          Fila1: orden.usuario?.nombres + ' ' + orden.usuario?.apellidos,
          Fila2: orden.usuario?.numeroDocumento,
        },
        tipoExamen: tiposExamen.find((x) => x.Id == orden.tipoExamenId)?.Nombre,
        estado: estadosOrden.find((x) => x.Id == orden.estadoOrdenId)?.Nombre,
        estadoAtencion:
          estadosAtencion
            .find((x) => x.Id === orden.estadoAtencionId)
            ?.Nombre.toUpperCase() ?? '',
        estadoOrdenId: orden.estadoOrdenId,
        sedeyFechaAtencion: {
          Fila1: validarSedeOAliado(orden?.sedeId, orden?.aliadoId),
          Fila2: orden?.aliadoId
            ? moment(orden?.fechaCreacion)?.format('YYYY-MM-DD')
            : moment(orden?.fechaCreacion)?.format('YYYY-MM-DD LT'),
        },
        concepto: validarConceptoServicios(orden),
        centroCostosEmpresaUsuaria: orden.empresaHijaId
          ? empresas
              .find((x) => x.Id == orden.empresaHijaId)
              ?.Nombre?.toUpperCase() ?? ''
          : empresas
              .find((x) => x.Id == orden.empresaId)
              ?.Nombre?.toUpperCase() ?? '',
        usuario: orden.usuario,
        urlCertificado: orden.urlCertificado,
        urlCertificadoAtencion: orden.urlCertificadoAtencion,
        servicios: orden?.servicios,
      })),
    }
    return Promise.resolve(ordenes)
  }
}

export const ObtenerHistoriaClinica = async (idUsuario) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'fachada-function/FachadaObtenerHistoriaClinicaPorPaciente?usuarioId=' +
      idUsuario
  )
}

export const ObtenerDetalleAtencion = async (atencionId) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'fachada-function/FachadaObtenerDetalleAtencion?atencionId=' + atencionId
  )
}

export const FachadaObtenerEjecucionAtencionesOrdenesMesEmpresaId = async (
  empresaId,
  fecha
) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'gato-api-function/ObtenerEjecucionAtencionesOrdenesMesEmpresaId',
    {
      params: {
        empresaId,
        fecha,
      },
    }
  )
}

export const RecargarSaldoCuenta = async (recarga) => {
  const restApi = await getRestApi()
  return await restApi.put('fachada-function/FachadaRecargarSaldo', recarga)
}

export const FachadaGenerarReciboCajaRecarga = async (recibo) => {
  const restApi = await getRestApi()
  return await restApi.post(
    'fachada-function/FachadaGenerarReciboCajaRecarga',
    recibo
  )
}

export const ValidarEstadoTransaccion = async (
  empresaId,
  transaccionId,
  ip
) => {
  const restApi = await getRestApi()
  return await restApi.get('fachada-function/FachadaValidarEstadoTransaccion', {
    params: {
      empresaId,
      transaccionId,
      ip,
    },
  })
}
