import React from 'react'
import {InputPorcentaje, InputMoneda} from './index'
import {aplicarFormatoMoneda} from '../../utils'

const InputConResultado = ({
  titulo,
  estilosTitulo = '',
  estilosInput,
  placeholder,
  valor,
  funcion,
  totalCalculado,
  descripcion,
  porcentaje = true,
  prefix = '$',
  deshabilitarTab = false,
  estilosDescripcion = '',
}) => {
  return (
    <div
      className={'w-full flex flex-wrap justify-start items-center ml-2 my-2'}
    >
      <span
        className={`w-6/12 text-cendiatra-verde-3 font-bold text-lg ${estilosTitulo}`}
      >
        {titulo}
      </span>
      {porcentaje ? (
        <>
          <InputPorcentaje
            estilosContenedor={'w-3/12 flex  items-center'}
            estilosInput={estilosInput}
            placeholder={placeholder}
            onValueChange={funcion}
            valor={valor}
            deshabilitarTab={deshabilitarTab}
          />
        </>
      ) : (
        <>
          <InputMoneda
            estilosContenedor={'w-3/12 flex items-center'}
            estilosInput={estilosInput}
            onValueChange={funcion}
            placeholder={placeholder}
            valor={valor}
            prefix={prefix}
            deshabilitarTab={deshabilitarTab}
          />
        </>
      )}
      <span className="w-2/12 text-cendiatra-verde-3 font-bold text-center text-lg">
        {totalCalculado ? aplicarFormatoMoneda(totalCalculado) : ''}
      </span>
      <span
        className={`w-full text-cendiatra-gris-1 font-bold text-lg ${estilosDescripcion}`}
      >
        {descripcion}
      </span>
    </div>
  )
}

export default InputConResultado
