import React, {useEffect, useState} from 'react'
import EncabezadoPestanaExternos from './EncabezadoPestanaExternos'

export default function BarraEncabezadosExternos({
  titulos,
  estado,
  cambioEstado,
  deshabilitarValidaciones,
}) {
  const [titulosAMapear, setTitulosAMapear] = useState([])
  const maximoTabs = 5

  useEffect(() => {
    if (titulos && titulos.length > 0) {
      dividirDatos()
    }
  }, [estado, titulos])

  const dividirDatos = () => {
    let min = (Math.ceil(estado / maximoTabs) - 1) * maximoTabs
    let max = Math.min(min + maximoTabs, titulos.length)
    setTitulosAMapear(titulos.slice(min, max))
  }

  return (
    <div className="w-11/12 flex justify-center pointer-events-auto">
      {titulosAMapear.map((titulo, posicion) => (
        <EncabezadoPestanaExternos
          estadoActual={estado}
          actualizarDatos={cambioEstado}
          numeroPaginacion={titulos.indexOf(titulo) + 1}
          titulo={titulo}
          key={posicion}
          deshabilitarValidaciones={deshabilitarValidaciones}
        />
      ))}
    </div>
  )
}
