import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  Fragment,
  useMemo,
} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {
  Input,
  ListaDesplegable,
  CampoFiltrar,
  ErroresInput,
  ListaDesplegableConSelector,
} from '../inputs'
import {
  ObtenerTiposExamen,
  ObtenerTiposDocumento,
  ObtenerCiudadesPorNombre,
  ObtenerServiciosOPaquetesPorTarifario,
  ObtenerServiciosYPaquetesPorTarifario,
  ObtenerEmpresasHijasPorIdPadre,
} from '../../cache/servicios-cache'
import {BotonSimple, BotonOpcion} from '../Botones'
import {ContextoOrden, ContextoAplicacion} from '../../contexto'
import moment from 'moment'
import {
  ObtenerServicioPorId,
  ObtenerUsuarioPorTipoDocumentoYDocumento,
} from '../../microservicios/'
import SeleccionServicios from './SeleccionServicios'
import TablaVisualizacionObservaciones from './TablaVisualizacionObservaciones'
import {useMsal} from '@azure/msal-react'
import {
  regexCorreoElectronico,
  FACTURACION,
  regexSoloLetrasYNumeros,
} from '../../constantes'
import ReactFlagsSelect from 'react-flags-select'
import {AlertaLongitudDocumento} from '../MostrarEnPantalla'

const FormularioOrden = ({manejarEnviar, manejarGuardar, modoEdicion}) => {
  const [tiposExamenes, setTiposExamenes] = useState([])
  const [tiposDocumento, setTiposDocumento] = useState([])
  const [minimoFechaFinal, setMinimoFechaFinal] = useState('')
  const [serviciosPaquetes, setServiciosPaquetes] = useState([])
  const [empresas, setEmpresas] = useState([])

  const contextoOrden = useContext(ContextoOrden)
  const contexto = useContext(ContextoAplicacion)

  const {accounts} = useMsal()
  const idEmpresa = accounts[0]?.idTokenClaims?.city ?? ''

  const [paisSeleccionado, setPaisSeleccionado] = useState('CO')
  const [countryOptions, setCountryOptions] = useState([])

  const [infoNumeroCaracteres, setInfoNumeroCaracteres] = useState({
    minimo: 6,
    maximo: 10,
    mensaje: '',
    tipoCampo: 'text',
  })

  const countryCodes = require('country-codes-list')

  const codigoDePaises = countryCodes.customList(
    'countryCode',
    '+{countryCallingCode}'
  )

  useEffect(() => {
    const fetchData = async () => {
      setCountryOptions(codigoDePaises)
    }

    fetchData()
  }, [])

  useEffect(() => {
    const valorSeleccionadoPais = codigoDePaises[paisSeleccionado]
    contextoOrden.setIndicativo(valorSeleccionadoPais)
    setValue('codigoPais', valorSeleccionadoPais)
  }, [paisSeleccionado])

  const obtenerCodigoPais = (indicativo) => {
    const valorSeleccionadoPais = Object.keys(codigoDePaises).find(
      (key) => codigoDePaises[key] === indicativo
    )
    return valorSeleccionadoPais
  }
  const {
    trigger,
    setValue,
    control,
    reset,
    formState: {errors, isValid},
    setError,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      activo: false,
    },
    mode: 'onChange',
  })

  const inputTipoDocumento = watch('tipoDeDocumento')
  const inputDocumento = watch('numeroDocumento')
  const fechaDeHoy = moment().format().slice(0, -15)
  const obtenerListaTarifario = useCallback(async () => {
    await ObtenerServiciosYPaquetesPorTarifario(contextoOrden.tarifario).then(
      (respuesta) => {
        setServiciosPaquetes(respuesta)
      }
    )
  }, [contextoOrden.tarifario])

  const obtenerEmpresasPadreYEmpresasHijas = async () => {
    const lista = await ObtenerEmpresasHijasPorIdPadre(idEmpresa)
    setEmpresas(
      lista
        .map((elemento) => ({
          Id: elemento.id,
          Nombre: elemento.razonSocial,
        }))
        .sort((a, b) => a.Nombre.localeCompare(b.Nombre))
    )
  }

  const obtenerInformacionInicial = useCallback(async () => {
    setTiposExamenes(await ObtenerTiposExamen())
    setTiposDocumento(await ObtenerTiposDocumento())
  }, [])

  useEffect(() => {
    obtenerInformacionInicial().catch(console.error)
  }, [obtenerInformacionInicial])

  useEffect(() => {
    obtenerEmpresasPadreYEmpresasHijas()
  }, [])

  useEffect(() => {
    const subscription = watch(() => {
      contextoOrden.setFormularioOrden(getValues())
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    trigger()
  }, [infoNumeroCaracteres])

  useEffect(() => {
    if (modoEdicion) {
      reset({
        ...contextoOrden.formularioOrden,
        activo: contextoOrden.formularioOrden.descontarSaldoPortal,
      })
      setValue('empresaHijaId', contextoOrden.formularioOrden?.empresaHijaId)
    } else {
      contextoOrden.setFormularioOrden({
        ciudad: '',
        tipoSubmit: '',
        usuario: {},
        fechaCreacion: moment().format('YYYY-MM-DD'),
      })
      contextoOrden.setExamenesSeleccionados([])
      setValue('fechaCreacion', moment().format('YYYY-MM-DD'))
    }
    trigger()
  }, [contextoOrden.actualizar])

  useEffect(() => {
    if (
      contextoOrden?.tarifario?.empresaId ||
      contextoOrden?.tarifario?.esPortafolio
    ) {
      obtenerListaTarifario()
    }
  }, [contextoOrden.tarifario])

  const obtenerInformacionDocumento = async () => {
    if (inputTipoDocumento && inputDocumento) {
      await ObtenerUsuarioPorTipoDocumentoYDocumento(
        inputDocumento,
        inputTipoDocumento
      ).then((respuesta) => {
        if (respuesta.status === 200) {
          setPaisSeleccionado(
            obtenerCodigoPais(respuesta.data?.indicativo) ?? 'CO'
          )
          setValue('nombre', respuesta.data?.nombres ?? '')
          setValue('apellido', respuesta.data?.apellidos ?? '')
          setValue('cargo', respuesta.data?.cargo ?? '')
          setValue('celular', respuesta.data?.celular ?? '')
          setValue('correo', respuesta.data?.correo ?? '')
          setValue('codigoPais', respuesta.data?.indicativo ?? '+57')
          trigger()
        }
      })
    }
  }
  useEffect(() => {
    obtenerInformacionDocumento()
  }, [inputTipoDocumento])

  const filtrarExamenes = async (e) => {
    contextoOrden.setExamen(e.target.value)
    if (e.target.value.length >= 2) {
      ObtenerServiciosOPaquetesPorTarifario(
        contextoOrden.tarifario,
        e.target.value
      ).then((res) => {
        contextoOrden.setExamenesFiltrados(res)
      })
    } else {
      contextoOrden.setExamenesFiltrados([])
      contextoOrden.setExamenParaAgregar('')
    }
  }

  const filtrarCiudades = async (e) => {
    setValue('ciudad', e.target.value)
    contextoOrden.setFormularioOrden({
      ...contextoOrden.formularioOrden,
      ciudad: e.target.value,
    })
    if (e.target.value.length >= 2) {
      ObtenerCiudadesPorNombre(e.target.value).then((res) => {
        contextoOrden.setCiudadesFiltradas(res)
      })
    } else {
      contextoOrden.setCiudadesFiltradas([])
      setValue('ciudad')
      setError('ciudad', {type: 'required'})
    }
  }

  const ciudadSeleccionada = (ciudad, onChange) => {
    onChange(ciudad)
    contextoOrden.setFormularioOrden({
      ...contextoOrden.formularioOrden,
      ciudad: ciudad.Nombre,
    })
    setValue('ciudad', ciudad.Nombre)
  }

  const seleccionarServicio = async (informacion) => {
    try {
      const servicios = contextoOrden?.examenesSeleccionados ?? []
      let copia = [...servicios]
      const row = {
        existe: Boolean(
          copia.find(
            (c) => c.Id === informacion.Id && c.Tipo === informacion.Tipo
          )
        ),
        index:
          copia.findIndex(
            (c) => c.Id === informacion.Id && c.Tipo === informacion.Tipo
          ) ?? null,
      }

      if (!row.existe) {
        if (informacion?.Tipo?.toLowerCase() === 'servicio' && !row.existe) {
          const respuesta = await ObtenerServicioPorId(informacion.Id)
          informacion.Observacion = respuesta.data?.observaciones
        } else if (
          informacion?.Tipo?.toLowerCase() === 'paquete' &&
          !row.existe
        ) {
          const servicios = await Promise.all(
            informacion?.ServiciosPaquete?.map(async (item) => {
              const respuesta = await ObtenerServicioPorId(item.Id)
              const observacion = respuesta.data?.observaciones ?? ''
              return {
                Id: item.Id,
                CodigoInterno: item.CodigoInterno,
                Nombre: item.Nombre,
                CupsId: item.CupsId,
                Observacion: observacion,
              }
            })
          )
          informacion.ServiciosPaquete = servicios
        }
        if (informacion.hasOwnProperty('Seleccionado')) {
          delete informacion.Seleccionado
        }
        copia.push(informacion)
      } else {
        copia.splice(row.index, 1)
      }
      contextoOrden.setExamenesSeleccionados(copia)
    } catch (error) {
      console.log(error)
    }
  }

  const quitarServicioSeleccionado = (informacion) => {
    const servicios = contextoOrden?.examenesSeleccionados ?? []
    const copia = [...servicios]

    const originalCopia = [...serviciosPaquetes]
    const indexOriginal = originalCopia.findIndex(
      (o) => o.Id === informacion.Id && o.Tipo === informacion.Tipo
    )

    const index = copia.findIndex(
      (c) => c.Id === informacion.Id && c.Tipo === informacion.Tipo
    )

    copia.splice(index, 1)
    originalCopia[indexOriginal].Seleccionado = false
    setServiciosPaquetes(originalCopia)
    contextoOrden.setExamenesSeleccionados(copia)
  }

  const restringirFechaFinal = (fecha) => {
    if (fecha == '') {
      setMinimoFechaFinal('')
      setValue('fechaVencimientoOrden', '')
    } else {
      setMinimoFechaFinal(moment(fecha).add(2, 'days').format('YYYY-MM-DD'))
      setValue(
        'fechaVencimientoOrden',
        moment(fecha).add(2, 'days').format('YYYY-MM-DD')
      )
    }
  }

  const listaObservaciones = useMemo(() => {
    return contextoOrden?.examenesSeleccionados
      ?.filter((item) => item?.Tipo?.toLowerCase() === 'servicio')
      .map((item) => ({Observacion: item?.Observacion, Tipo: item.Tipo}))
      .filter((item) => item.Observacion)
      .concat(
        ...contextoOrden?.examenesSeleccionados
          ?.filter((item) => item?.Tipo?.toLowerCase() !== 'servicio')
          .map((item) =>
            item?.ServiciosPaquete?.map((s) => ({
              Observacion: s?.Observacion,
              Tipo: item.Tipo,
            })).filter((item) => item.Observacion)
          )
      )
  }, [contextoOrden.examenesSeleccionados])

  const descontarSaldoValor = (onChange, valorActual) => {
    onChange(!valorActual)
    contextoOrden.setDescontarSaldo(!valorActual)
  }

  const manejarMunicipioSeleccionado = (empresa) => {
    setValue('empresaHijaId', empresa?.Id)
  }

  return (
    <Fragment>
      <div className="flex flex-col justify-center pb-20">
        <div className="w-9/12 m-auto mb-10">
          <div className="w-full">
            <form>
              <div className="w-full flex flex-wrap">
                <div className="w-1/3 p-2">
                  <Controller
                    name="fechaCreacion"
                    control={control}
                    render={({field: {ref, value, ...rest}}) => (
                      <Input
                        {...rest}
                        estilosContenedor={'w-12/12'}
                        estilosInput={
                          'appearance-none rounded relative block w-full p-1.5 border text-white rounded-lg  bg-cendiatra-gris-4 border-cendiatra-gris-4 text-white'
                        }
                        tipo={'date'}
                        titulo={'Fecha creación de la orden'}
                        deshabilitado={true}
                        fechaMinima={''}
                        valor={value}
                      />
                    )}
                  />
                </div>
                <div className="w-1/3 p-2">
                  <Controller
                    name="fechaSolicitudAtencion"
                    control={control}
                    rules={{
                      required: true,
                      onChange: (e) => restringirFechaFinal(e.target.value),
                    }}
                    render={({field: {onChange, value}}) => (
                      <Input
                        onChange={onChange}
                        estilosContenedor={'w-full'}
                        estilosInput={
                          Object.keys(errors).find(
                            (element) => element === 'fechaSolicitudAtencion'
                          )
                            ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                            : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                        }
                        tipo={'date'}
                        titulo={'Fecha solicitada para atención*'}
                        valor={value}
                        fechaMinima={fechaDeHoy}
                      />
                    )}
                  />
                </div>
                <div className="w-1/3 p-2">
                  <Controller
                    name="fechaVencimientoOrden"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({field: {onChange, value}}) => (
                      <Input
                        estilosContenedor={'w-full'}
                        onChange={onChange}
                        estilosInput={
                          Object.keys(errors).find(
                            (element) => element === 'fechaVencimientoOrden'
                          )
                            ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                            : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                        }
                        tipo={'date'}
                        titulo={'Fecha de vencimiento orden'}
                        valor={value}
                        fechaMinima={minimoFechaFinal}
                      />
                    )}
                  />
                </div>
                <div className="w-full justify-center items-center flex">
                  <div className="w-2/4 p-2">
                    <Controller
                      name="ciudad"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({field: {onChange, value}}) => (
                        <CampoFiltrar
                          titulo={'Ciudad de atención*'}
                          estilosPersonalizados={'w-full'}
                          textoTitulo={''}
                          estilosInput={
                            Object.keys(errors).find(
                              (element) => element === 'ciudad'
                            )
                              ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                              : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                          }
                          placeholder={'Descripción (Autocompletar)'}
                          tipo={'text'}
                          valorDelCampoFiltro={value}
                          desactivarOtroFiltro={''}
                          informacionFiltrada={contextoOrden.ciudadesFiltradas}
                          handleChange={filtrarCiudades}
                          handleOptionChange={(e) => {
                            ciudadSeleccionada(e, onChange)
                          }}
                          id={1}
                        />
                      )}
                    />
                  </div>
                  <div className="w-2/4 p-2">
                    <Controller
                      name="empresaHijaId"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({field: {onChange, value}}) => (
                        <ListaDesplegableConSelector
                          titulo={
                            'Seleccione el centro de costo o empresa usuaria'
                          }
                          estilosPersonalizados={'w-full'}
                          estilosInput={`appearance-none uppercase rounded relative block w-full text-center  border border-cendiatra text-center rounded-lg focus:outline-none focus:ring-indigo-500  focus:z-10 sm:text-sm`}
                          textoPorDefecto={'ELEGIR'}
                          placeholder={'EMPRESA'}
                          datosParaMostrar={empresas}
                          manejarOpcionSeleccionada={
                            manejarMunicipioSeleccionado
                          }
                          valor={value}
                          textoToolTip={''}
                          colorTextoPersonalizado={'text-cendiatra-gris-3'}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="w-full justify-center items-center flex">
                  <div className="w-full sm:w-1/1 flex flex-wrap">
                    <div className="w-2/4 p-2">
                      <Controller
                        name="tipoDeDocumento"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({field: {onChange, value}}) => (
                          <ListaDesplegable
                            onChange={onChange}
                            estilosContenedor={'w-full'}
                            estilosLista={
                              Object.keys(errors).find(
                                (element) => element === 'tipoDeDocumento'
                              )
                                ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                                : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                            }
                            titulo={'Tipo de documento*'}
                            opciones={tiposDocumento}
                            valor={value}
                          />
                        )}
                      />
                    </div>
                    <div className="w-2/4 p-2">
                      <Controller
                        name="numeroDocumento"
                        control={control}
                        rules={{
                          required: true,
                          pattern: regexSoloLetrasYNumeros,
                          minLength: infoNumeroCaracteres.minimo,
                          maxLength: infoNumeroCaracteres.maximo,
                        }}
                        render={({
                          field: {onChange, value},
                          fieldState: {error},
                        }) => (
                          <Input
                            onChange={onChange}
                            estilosContenedor={'w-full'}
                            onBlur={() => {
                              obtenerInformacionDocumento()
                            }}
                            estilosInput={
                              Object.keys(errors).find(
                                (element) => element === 'numeroDocumento'
                              )
                                ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                                : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                            }
                            tipo={infoNumeroCaracteres.tipoCampo || 'text'}
                            placeholder={'Descripción'}
                            titulo={'No de documento*'}
                            valor={value}
                          />
                        )}
                      />
                      <ErroresInput
                        erroresFormulario={errors}
                        nombre={'numeroDocumento'}
                        tipoError={'pattern'}
                        mensaje={'Se permiten letras y números'}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full flex justify-start items-center">
                  <AlertaLongitudDocumento
                    tipoDocumento={watch('tipoDeDocumento')}
                    informacionCaracteres={infoNumeroCaracteres}
                    setInformacionCaracteres={setInfoNumeroCaracteres}
                    numeroDocumento={watch('numeroDocumento')}
                    errors={errors}
                  />
                </div>
                <div className="w-1/3 p-2">
                  <Controller
                    name="nombre"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({field: {onChange, value}}) => (
                      <Input
                        onChange={onChange}
                        estilosContenedor={'w-full'}
                        estilosInput={
                          Object.keys(errors).find(
                            (element) => element === 'nombre'
                          )
                            ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                            : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                        }
                        tipo={'text'}
                        placeholder={'Descripción'}
                        titulo={'Nombres*'}
                        valor={value}
                      />
                    )}
                  />
                </div>
                <div className="w-1/3 p-2">
                  <Controller
                    name="apellido"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({field: {onChange, value}}) => (
                      <Input
                        onChange={onChange}
                        estilosContenedor={'w-full'}
                        estilosInput={
                          Object.keys(errors).find(
                            (element) => element === 'apellido'
                          )
                            ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                            : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                        }
                        tipo={'text'}
                        placeholder={'Descripción'}
                        titulo={'Apellidos*'}
                        valor={value}
                      />
                    )}
                  />
                </div>
                <div className="w-1/3 p-2">
                  <Controller
                    name="cargo"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({field: {onChange, value}}) => (
                      <Input
                        onChange={onChange}
                        estilosContenedor={'w-full'}
                        estilosInput={
                          Object.keys(errors).find(
                            (element) => element === 'cargo'
                          )
                            ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                            : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                        }
                        tipo={'text'}
                        placeholder={'Descripción'}
                        titulo={'Cargo*'}
                        valor={value}
                      />
                    )}
                  />
                </div>
                <div className="w-1/3 p-2">
                  <Controller
                    name="tipoExamen"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({field: {onChange, value}}) => (
                      <ListaDesplegable
                        onChange={onChange}
                        estilosContenedor={'w-full'}
                        estilosLista={
                          Object.keys(errors).find(
                            (element) => element === 'tipoExamen'
                          )
                            ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                            : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                        }
                        titulo={'Tipo de exámen*'}
                        opciones={tiposExamenes}
                        valor={value}
                      />
                    )}
                  />
                </div>
                <div className="w-1/3 p-2 flex justify-center items-center gap-2">
                  <Controller
                    name="codigoPais"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={() => (
                      <ReactFlagsSelect
                        selected={paisSeleccionado}
                        onSelect={(code) => setPaisSeleccionado(code)}
                        customLabels={countryOptions}
                        placeholder="Indicativo"
                        searchable
                        searchPlaceholder="Buscar"
                        className="w-9/12 top-4"
                        selectButtonClassName={
                          '!rounded-lg !p-1.5 !h-9 !border-cendiatra'
                        }
                      />
                    )}
                  />
                  <Controller
                    name="celular"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({field: {onChange, value}}) => (
                      <Input
                        onChange={onChange}
                        estilosContenedor={'w-full'}
                        estilosInput={
                          Object.keys(errors).find(
                            (element) => element === 'celular'
                          )
                            ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                            : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                        }
                        tipo={'number'}
                        placeholder={'Descripción'}
                        titulo={'Celular (incluir indicativo)*'}
                        valor={value}
                      />
                    )}
                  />
                </div>
                <div className="w-1/3 p-2">
                  <Controller
                    name="correo"
                    control={control}
                    rules={{
                      required: true,
                      pattern: regexCorreoElectronico,
                    }}
                    render={({field: {onChange, value}}) => (
                      <div className="w-full flex flex-wrap justify-center items-center">
                        <Input
                          onChange={onChange}
                          estilosContenedor={'w-full'}
                          estilosInput={
                            Object.keys(errors).find(
                              (element) => element === 'correo'
                            )
                              ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                              : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                          }
                          tipo={'email'}
                          placeholder={'Descripción'}
                          titulo={'Correo*'}
                          valor={value}
                        />
                        <div className="w-full flex justify-start items-center">
                          <span className="text-red-500 ">
                            {errors.correo?.type === 'pattern' &&
                              'Ej: ejemplo@ejemplo.com'}
                          </span>
                        </div>
                      </div>
                    )}
                  />
                </div>
                <div className="w-full p-2">
                  <Controller
                    name="observacionesMedicas"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({field: {onChange, value}}) => (
                      <Input
                        onChange={onChange}
                        estilosContenedor={'w-full'}
                        estilosInput={
                          'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm  h-20'
                        }
                        tipo={'texto'}
                        placeholder={'Descripción'}
                        titulo={'Observaciones médicas'}
                        valor={value}
                      />
                    )}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="w-full mb-8">
          <SeleccionServicios
            serviciosPaquetes={serviciosPaquetes}
            seleccionarServicio={seleccionarServicio}
            serviciosSeleccionados={contextoOrden?.examenesSeleccionados ?? []}
            quitarServicioSeleccionado={quitarServicioSeleccionado}
          />
        </div>
        <div className="w-full">
          <TablaVisualizacionObservaciones
            observaciones={listaObservaciones}
            titulo={''}
          />
        </div>

        {(contexto.infoEmpresa?.tipoFacturacion ?? '').toLowerCase() ===
          FACTURACION.CONTADO && (
          <div className="w-full text-center mt-90px">
            <p className="text-cendiatra text-2xl font-bold mb-4">
              {' '}
              ¿Desea descontar esta orden del saldo disponible en la bolsa de
              servicios?{' '}
            </p>

            <Controller
              name="activo"
              control={control}
              render={({field: {onChange, value}}) => (
                <BotonOpcion
                  id={'descontar'}
                  estaActivo={value}
                  funcionClick={() => descontarSaldoValor(onChange, value)}
                />
              )}
            />
          </div>
        )}

        <div className="w-full flex justify-center items-center mt-90px">
          <BotonSimple
            texto={'Generar orden'}
            estilosBoton={
              Object.keys(errors).length ||
              !contextoOrden.examenesSeleccionados.length > 0
                ? 'w-32 h-40px p-1.5 rounded-lg bg-cendiatra-gris-4 bg-cover bg-no-repeat bg-center text-white font-bold  text-14px mx-25px pointer-events-none	'
                : 'w-32 h-40px p-1.5 rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white  font-bold text-14px mx-25px'
            }
            funcion={manejarEnviar}
            tipoDeBoton={'button'}
            deshabilitado={
              !Object.keys(errors) ||
              !contextoOrden.examenesSeleccionados.length > 0
            }
          />
          <BotonSimple
            texto={'Guardar orden'}
            estilosBoton={
              !Object.keys(errors) || contextoOrden.estadoOrden == 'ABIERTA'
                ? 'w-32 h-40px p-1.5 rounded-lg bg-cendiatra-gris-4 bg-cover bg-no-repeat bg-center text-white font-bold  text-14px mx-25px pointer-events-none	'
                : 'w-32 h-40px p-1.5 rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white  font-bold text-14px mx-25px'
            }
            tipoDeBoton={'button'}
            funcion={manejarGuardar}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default FormularioOrden
