import {getRestApi} from './ApiAxios'

export const ObtenerExamenesMasFrecuentesPorEmpresaId = async (id) => {
  const restApi = await getRestApi()
  return await restApi.get('gato-api-function/ObtenerExamenesMasFrecuentes', {
    params: {empresaId: id},
  })
}

export const ObtenerFacturadoAtencionesPorMesYEmpresaId = async (
  empresaId,
  periodo
) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'gato-api-function/ObtenerFacturadoAtencionesMesPorEmpresaId',
    {
      params: {
        empresaId,
        periodo,
      },
    }
  )
}
