import React, {useState} from 'react'
import TablaMovientosYDetalles from '../../componentes/usuarioContable/TablaMovimientosYDetalles'
import useValidacionTransaccionPendiente from '../../componentes/usuarioContable/ValidacionTransaccionPendiente'
import {ContenedorPagina} from '../../componentes/Contenedores'
import BarraEncabezadosExternos from '../../componentes/MostrarEnPantalla/BarraEncabezadosExternos'
import clsx from 'clsx'
import TablaPagosOtrosServicios from '../../componentes/usuarioContable/TablaPagosOtrosServicios'

const DetalleYMovimientos = () => {
  useValidacionTransaccionPendiente()

  const [numeroPagina, setNumeroPagina] = useState(1)

  const listaPestanas = [
    'Recarga y consumo de saldo',
    'Pagos de otros servicios',
  ]
  return (
    <>
      <div className="w-full flex justify-center items-center">
        <span className="w-full text-cendiatra-verde-2 text-3xl text-center font-extrabold mt-10 mb-5">
          MOVIMIENTOS Y DETALLE
        </span>
      </div>
      <ContenedorPagina tituloPagina={''}>
        <div
          className="w-full flex justify-center items-center flex-wrap "
          style={{marginTop: '-6.3rem'}}
        >
          <BarraEncabezadosExternos
            titulos={listaPestanas}
            estado={numeroPagina}
            cambioEstado={setNumeroPagina}
          />
        </div>
        <div
          className={clsx(
            'w-full',
            numeroPagina === 1 ? 'flex' : 'hidden',
            'justify-center',
            'items-center',
            'flex-wrap'
          )}
        >
          <TablaMovientosYDetalles />
        </div>
        <div
          className={clsx(
            'w-full',
            numeroPagina === 2 ? 'flex' : 'hidden',
            'justify-center',
            'items-center',
            'flex-wrap'
          )}
        >
          <TablaPagosOtrosServicios />
        </div>
      </ContenedorPagina>
    </>
  )
}

export default DetalleYMovimientos
